import PropTypes from 'prop-types';
import { edges } from '../utils/edges';

export const propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: edges,
        }).isRequired,
    }).isRequired,
    pageContext: PropTypes.shape({
        page: PropTypes.number.isRequired,
        offset: PropTypes.number.isRequired,
        postsPerPage: PropTypes.number.isRequired,
        hasPrevPage: PropTypes.bool.isRequired,
        hasNextPage: PropTypes.bool.isRequired,
    }).isRequired,
};
